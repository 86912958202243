<template>
  <div class="content-wrap">
    <div class="filters">
      <!-- 签到状态 -->
      <ListFilter
        only
        :name="$t('train.sign_in_status')"
        :dataSource="signIn"
        @onChange="signInChange"
      />
      <!-- 签退状态 -->
      <ListFilter
        only
        :name="$t('train.sign_out_status')"
        :dataSource="signOut"
        @onChange="signOutChange"
      />
      <!-- 出勤状态 -->
      <ListFilter
        only
        :name="$t('train.attendance_status')"
        :dataSource="learnStatus"
        @onChange="learnChange"
      />
    </div>
    <div class="ctrl">
      <div class="btns">
        <a-button @click="batchClock">{{
          $t("train.batch_operation")
        }}</a-button>
        <!-- 批量操作 -->
        <a-button @click="importXsl">{{
          $t("train.import_attendance")
        }}</a-button>
        <!-- 导入考勤 -->
      </div>
      <div class="search">
        <div class="search-icon" @click="handleReload">
          <SearchOutlined style="color: #656565" />
        </div>
        <a-input
          class="search-input"
          v-model:value.trim="queryParam.keyword"
          :placeholder="$t('please_enter_keywords')"
          allow-clear
          @pressEnter="handleReload"
        />
        <!-- 请输入关键字 -->
      </div>
    </div>
    <div class="table-wrap">
      <a-table
        size="middle"
        :scroll="{ x: 854 }"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :data-source="state.dataSource"
        rowKey="userId"
        :bordered="false"
        :pagination="pagination"
        :loading="state.loading"
        @change="handleTableChange"
      >
        <template #realName="{ record }">
          <div class="td-account">
            <div class="portrait">
              <img :src="record.portrait" alt="portrait" />
            </div>
            <div class="text">
              <p>{{ record.account }}</p>
              <p>
                <OpenData type="userName" :openid="record.realName" />
              </p>
            </div>
          </div>
        </template>
        <template #inTimeStatus="{ record }">
          <span style="color: #38cdb1" v-if="record.signInStatus == 1">
            {{ $t("Pub_Btn_AlreadySign") }}
            <!-- 已签到 -->
          </span>
          <span style="color: red" v-else-if="record.signInStatus == 2">
            {{ $t("train.be_late") }}
            <!-- 迟到 -->
          </span>
          <span v-else>{{ $t("train.no_sign") }}</span>
          <!-- 未签到 -->
        </template>
        <template #outTimeStatus="{ record }">
          <span style="color: #38cdb1" v-if="record.signOutStatus == 3">
            {{ $t("train.signed_out") }}
            <!-- 已签退 -->
          </span>
          <span style="color: red" v-else-if="record.signOutStatus == 4">
            {{ $t("train.leave_early") }}
            <!-- 早退 -->
          </span>
          <span v-else>{{ $t("train.no_sign_out") }}</span>
          <!-- 未签退 -->
        </template>
        <template #learnStatus="{ text }">
          <span style="color: #e63450" v-if="text == 1">{{
            $t("train.absent")
          }}</span>
          <!-- 未出勤 -->
          <span style="color: #38cdb1" v-else-if="text == 2">{{
            $t("train.attended")
          }}</span>
          <!-- 已出勤 -->
          <span v-else-if="text == 3">{{ $t("train.ask_for_leave") }}</span>
          <!-- 请假 -->
        </template>
        <template #dateFormat="{ text }">
          {{ dateFormat(text) }}
        </template>
      </a-table>
    </div>
  </div>
  <a-modal
    width="700px"
    v-model:visible="visible"
    :title="modalData[modalType] ? modalData[modalType].title : ''"
    :confirmLoading="modalLoading"
    @ok="modalOk"
    @cancel="modalCancel"
  >
    <div class="modal-wrap">
      <template v-if="modalType == 'clocking'">
        <p class="tips">
          {{ $t("train.batch_set_attendance", [selectedRowKeys.length]) }}
          <!-- 当前为所选 人批量进行人工考勤设置-->
        </p>
        <a-form layout="vertical">
          <a-row>
            <a-col :span="4">
              <a-checkbox
                v-model:checked="modalData[modalType].signInShow"
                style="line-height: 32px"
                :disabled="modalData[modalType].isLeave"
              >
                {{ $t("Lab_LessonDetail_T_SignIn") }}
                <!-- 签到 -->
              </a-checkbox>
            </a-col>
            <a-col :span="20">
              <a-form-item name="inTime">
                <a-date-picker
                  style="width: 100%"
                  allowClear
                  v-model:value="modalData[modalType].inTime"
                  :show-time="{ format: 'HH:mm' }"
                  format="YYYY-MM-DD HH:mm"
                  valueFormat="YYYY-MM-DD HH:mm"
                  :placeholder="$t('train.please_select_sign_in_time')"
                  :disabled="modalData[modalType].isLeave"
                >
                  <!-- 请选择签到时间 -->
                  <template #suffixIcon>
                    <CalendarOutlined />
                  </template>
                </a-date-picker>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4">
              <a-checkbox
                v-model:checked="modalData[modalType].outTimeShow"
                style="line-height: 32px"
                :disabled="modalData[modalType].isLeave"
              >
                {{ $t("Lab_LessonDetail_T_SignOut") }}
                <!-- 签退 -->
              </a-checkbox>
            </a-col>
            <a-col :span="20">
              <a-form-item name="outTime">
                <a-date-picker
                  allowClear
                  v-model:value="modalData[modalType].outTime"
                  :show-time="{ format: 'HH:mm' }"
                  format="YYYY-MM-DD HH:mm"
                  valueFormat="YYYY-MM-DD HH:mm"
                  :placeholder="$t('train.please_select_sign_out_time')"
                  style="width: 100%"
                  :disabled="modalData[modalType].isLeave"
                >
                  <!-- 请选择签退时间 -->
                  <template #suffixIcon>
                    <CalendarOutlined />
                  </template>
                </a-date-picker>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="6">
              <a-checkbox
                v-model:checked="modalData[modalType].isLeave"
                @change="
                  modalData[modalType].isLeave
                    ? (modalData[modalType].signInShow = modalData[
                        modalType
                      ].outTimeShow =
                        false)
                    : ''
                "
                style="line-height: 32px"
              >
                {{ $t("train.ask_for_leave") }}
                <!-- 请假 -->
              </a-checkbox>
            </a-col>
          </a-row>
        </a-form>
      </template>
      <template v-else-if="modalType == 'import'">
        <div>
          {{ $t("train.attendance_temp", 0) }}
          <!-- 请先点击 -->
          <a-button type="link" @click="downloadTemplate" style="padding: 0">{{
            $t("train.attendance_temp", 1)
          }}</a-button>
          <!-- 下载考勤模板 -->
          {{ $t("train.attendance_temp", 2) }}
          <!-- ，编辑完内容后上传模板导入考勤 -->
        </div>
        <a-upload-dragger
          v-model:fileList="importFileList"
          :multiple="false"
          :action="uploadUrl + 'lecturer/train/importtemplate'"
          method="post"
          :headers="getHeader()"
          @change="handleChangeImport"
          :before-upload="handelBeforeUpload"
        >
          <p class="ant-upload-drag-icon">
            <file-excel-outlined />
          </p>
          <a-button class="t-mgb">
            {{
              importFileList.length > 0
                ? $t("train.reselect")
                : $t("train.select_file")
            }}
            <!-- "重新选择" : "选择文件" -->
          </a-button>
          <p class="ant-upload-hint">
            {{ $t("train.drop_file_attendance") }}
            <!-- 可直接将文件拖拽到此处上传，支持文件类型：xls .xlsx -->
          </p>
        </a-upload-dragger>
      </template>
    </div>
  </a-modal>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useFetchData } from "@/utils/useFetchData";
import {
  trainMembers,
  trainMembersSubmit,
  importAttendance,
} from "@/api/teacher";
import {
  uploadUrl,
  getHeader,
  fileDownload,
  getCdnUrl,
  dateFormat,
} from "@/utils/tools";
import ListFilter from "@/components/filter/ListFilter.vue";
import OpenData from "@/components/OpenData.vue";
import { FileExcelOutlined } from "@ant-design/icons-vue";
export default {
  components: {
    ListFilter,
    OpenData,
    FileExcelOutlined,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    taskId: {
      type: Number,
      default: 0,
    },
    detailId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const stateData = reactive({
      signIn: [
        { id: 1, name: $t("Pub_Btn_AlreadySign") }, // 已签到
        { id: 2, name: $t("train.no_sign_out") }, // 未签到
      ],
      signOut: [
        { id: 1, name: $t("train.signed_out") }, // 已签退
        { id: 2, name: $t("train.no_sign_out") }, // 未签退
      ],
      learnStatus: [
        { id: 2, name: $t("train.attended") }, // 已出勤
        { id: 1, name: $t("train.absent") }, // 未出勤
        { id: 3, name: $t("train.ask_for_leave") }, // 请假
      ],
      selectedRowKeys: [],
      columns: [
        {
          title: $t("train.student"),
          // title: "学员",
          width: 220,
          slots: { customRender: "realName" },
        },
        {
          title: $t("train.sign_in_time"),
          // title: "签到时间",
          dataIndex: "inTime",
          width: 150,
          slots: { customRender: "dateFormat" },
          align: "center",
        },
        {
          title: $t("train.sign_out_time"),
          // title: "签退时间",
          dataIndex: "outTime",
          width: 150,
          slots: { customRender: "dateFormat" },
          align: "center",
        },
        {
          title: $t("train.sign_in_status"),
          // title: "签到状态",
          dataIndex: "inTime",
          width: 90,
          slots: { customRender: "inTimeStatus" },
          align: "center",
        },
        {
          title: $t("train.sign_out_status"),
          // title: "签退状态",
          dataIndex: "outTime",
          width: 90,
          slots: { customRender: "outTimeStatus" },
          align: "center",
        },
        {
          title: $t("train.attendance_status"),
          // title: "出勤状态",
          dataIndex: "learnStatus",
          width: 90,
          slots: { customRender: "learnStatus" },
          align: "center",
        },
      ],
      visible: false,
      modalType: "",
      modalLoading: false,
      modalData: {
        clocking: {
          title: $t("train.batch_operation"),
          // 批量考勤
          signInShow: false,
          inTime: 0,
          outTimeShow: false,
          outTime: 0,
          isLeave: false,
        },
        import: {
          title: $t("train.import_attendance"),
          // 导入考勤
          // signInShow: false,
          // inTime: 0,
          // outTimeShow: false,
          // outTime: 0,
          // isLeave: false,
        },
      },
      importFileList: [],
    });

    const queryParam = reactive({
      taskId: props.taskId,
      detailId: props.detailId,
      keyword: "",
      isIn: 0,
      isOut: 0,
      isAttendance: 0,
    });

    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return trainMembers(form).then((res) => {
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle",
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });
      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    const onSelectChange = (selectedRowKeys) => {
      stateData.selectedRowKeys = selectedRowKeys;
    };

    const handleReload = () => {
      stateData.selectedRowKeys = [];
      reload();
    };

    const signInChange = (arr) => {
      queryParam.isIn = arr[0];
      handleReload();
    };

    const signOutChange = (arr) => {
      queryParam.isOut = arr[0];
      handleReload();
    };

    const learnChange = (arr) => {
      queryParam.isAttendance = arr[0];
      handleReload();
    };

    const batchClock = () => {
      console.log(props.data);
      if (stateData.selectedRowKeys.length) {
        stateData.modalType = "clocking";
        stateData.visible = true;
        // let dateArray = [
        //   new Date(props.data.trainTime[0]).getTime() / 1000,
        //   new Date(props.data.trainTime[1]).getTime() / 1000,
        // ];
        //签到日期范围
        // let signInDateRange = [
        //   Number(dateArray[0] - 60 * 60),
        //   Number(dateArray[0] + 15 * 60),
        // ];
        //签退日期范围
        // let signOutDateRange = [
        //   Number(dateArray[1] - 15 * 60),
        //   Number(dateArray[1] + 60 * 60),
        // ];
        //时间格式处理
        stateData.modalData.clocking.inTime = dateFormat(
          props.data.inStartTime,
          "YYYY-MM-DD HH:mm"
        );
        stateData.modalData.clocking.outTime = dateFormat(
          props.data.outStartTime,
          "YYYY-MM-DD HH:mm"
        );
      } else {
        proxy.$message.warn($t("train.please_select_a_student"));
        // 请勾选学员
      }
    };

    const trainMembersSubmitFn = () => {
      let formState = stateData.modalData[stateData.modalType];
      if (
        !formState.signInShow &&
        !formState.outTimeShow &&
        !formState.isLeave
      ) {
        proxy.$message.warn($t("train.checked_one"));
        // 至少勾选一项复选框的内容
        return false;
      }
      let params = {
        taskId: props.taskId,
        detailId: props.detailId,
        ids: stateData.selectedRowKeys,
      };
      if (formState.signInShow) {
        if (!formState.inTime) {
          proxy.$message.warn($t("train.please_select_sign_in_time"));
          // 请输入签到时间
          return false;
        }
        params.inTime = formState.inTime;
      }
      if (formState.outTimeShow) {
        if (!formState.outTime) {
          proxy.$message.warn($t("train.please_select_sign_out_time"));
          // 请输入签退时间
          return false;
        }
        params.outTime = formState.outTime;
      }
      if (formState.isLeave) {
        params.isLeave = 1;
      }
      stateData.modalLoading = true;
      trainMembersSubmit(params).then((res) => {
        stateData.modalLoading = false;
        proxy.$message.success($t("CM_Success"));
        // 操作成功！
        stateData.visible = false;
        stateData.modalType = "";
        handleReload();
      });
    };

    const importXsl = () => {
      stateData.modalType = "import";
      stateData.visible = true;
    };
    const handelBeforeUpload = (file) => {
      let fileType =
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      if (file.type == "") {
        let type = file.name.substr(file.name.lastIndexOf(".") + 1);
        fileType = type == "xls" || type == "xlsx";
      }
      if (!fileType) {
        proxy.$message.error($t("train.wrong_file_type"));
        // 文件类型错误,请选择xls/xlsx文件
      }
      return fileType;
    };

    const handleChangeImport = (info) => {
      if (info.file.status == "done") {
        if (info.file.response.ret == 0) {
          stateData.importFileList = [info.file];
        } else {
          proxy.$message.error(info.file.response.msg);
          stateData.importFileList = [];
        }
      } else if (info.file.status != "uploading") {
        info.fileList.forEach((item, i) => {
          if (item.percent == 0) {
            info.fileList.splice(i, 1);
          }
        });
      }
    };
    const downloadTemplate = () => {
      fileDownload(
        getCdnUrl("面授考勤模板.xlsx", "template"),
        $t("train.attendance_template")
      );
    };
    const importOk = () => {
      if (stateData.importFileList == 0) {
        // 请上传人员模板
        proxy.$message.warn($t("train.please_upload_template_file"));
        // 请上传模板文件
        return;
      }
      stateData.modalLoading = true;
      let fileName = stateData.importFileList[0].response.data;
      importAttendance({
        taskId: queryParam.taskId,
        detailId: queryParam.detailId,
        file: fileName,
      })
        .then((res) => {
          if (res.ret == 0) {
            if (res.data == 0) {
              proxy.$message.success(
                $t("train.operation_completed_without_importing_any_data")
              );
              // 操作完成，未导入任何数据！
            } else {
              proxy.$message.success(
                $t("train.import_nth_data", [res.data.success])
              );
              // 操作完成，成功导入 条数据
            }
            handleReload();
          } else {
            !proxy.$message &&
              proxy.$message.error($t("CM_Fail") + "," + res.msg);
            // 操作失败
          }
        })
        .finally(() => {
          stateData.visible = false;
          stateData.modalType = "";
          stateData.importFileList = [];
          stateData.modalLoading = false;
        });
    };

    const modalOk = () => {
      if (stateData.modalType == "clocking") {
        trainMembersSubmitFn();
      } else if (stateData.modalType == "import") {
        importOk();
      }
    };

    const modalCancel = () => {
      stateData.visible = false;
      stateData.modalType = "";
    };

    return {
      dateFormat,
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      handleReload,
      onSelectChange,
      signInChange,
      signOutChange,
      learnChange,
      batchClock,
      modalOk,
      modalCancel,
      importXsl,
      uploadUrl,
      getHeader,
      handelBeforeUpload,
      handleChangeImport,
      downloadTemplate,
    };
  },
};
</script>

<style lang="less" scoped>
.content-wrap {
  background-color: #fff;
  padding: 0 24px 14px;
  .filters {
    ::v-deep(.filter) {
      .label {
        width: 78px !important;
      }
      .values {
        width: calc(100% - 88px) !important;
      }
    }
  }
  .ctrl {
    .mixinFlex(space-between; center);
    background-color: #f5f5f6;
    padding: 10px 15px;
    .btns {
      button {
        margin-right: 15px;
      }
    }
    .search {
      .mixinFlex(space-between; center);
      width: 220px;
      height: 32px;
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
      &-icon {
        width: 34px;
        height: 14px;
        .mixinFlex(center; center);
        border-right: 1px solid #bbbbbb;
        cursor: pointer;
      }
      ::v-deep(.ant-input-affix-wrapper-focused) {
        border-color: none;
        box-shadow: none;
      }
      &-input {
        border: none;
        width: calc(100% - 34px);
        height: 100%;
        background-color: #fff;
        padding: 0 8px;
        font-size: 14px;
        ::v-deep(.ant-input) {
          background-color: #fff;
        }
      }
    }
  }
  .table-wrap {
    min-height: 756px;
    margin-top: 20px;
    ::v-deep(td) {
      vertical-align: middle !important;
    }
    ::v-deep(.ant-table-pagination.ant-pagination) {
      float: none;
      text-align: center;
    }
    .action {
      ::v-deep(.ant-btn) {
        padding-right: 4px;
        padding-left: 0;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .td-account {
      .mixinFlex(space-between; center);
      .portrait {
        .mixinImgWrap(40px; 40px);
        border-radius: 50%;
      }
      .text {
        width: calc(100% - 50px);
        p {
          margin: 0;
          font-size: 14px;
          color: #666;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
